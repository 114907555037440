<template>
  <div class="ProductTeaser">
    <v-card elevation="0" class="transparent">
      <div class="d-flex flex-no-wrap">
        <v-sheet elevation="1" rounded class="pa-2 thumb">
          <figure>
            <img :src="imageLink" alt="" />
          </figure>
        </v-sheet>
        <div class="meta">
          <h2>{{ item.name }}</h2>
          <h5>{{ item.price }} Pont</h5>
          <h4>{{ item.description }}</h4>
          <div class="actions">
            <v-btn text elevation="1" @click="editItem()">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn text color="primary" elevation="1" @click="deleteItem()">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import { IMAGE_BASE } from "../util/constants.js";
const DEFAULT_IMAGE_URL = "./assets/default_product_image";
export default {
  name: "ProductTeaser",
  props: ["item"],
  methods: {
    editItem() {
      eventBus.$emit("EDIT_ITEM", this.item);
    },
    deleteItem() {
      eventBus.$emit("DELETE_ITEM", this.item);
    },
  },
  computed: {
    imageLink() {
      if (this.item.productImages.length > 0) {
        let path = this.item.productImages[0].dirPath.substring(1);
        let name = this.item.productImages[0].filename;
        return `${IMAGE_BASE}${path}${name}`;
      }

      return `${DEFAULT_IMAGE_URL}`;
    },
  },
};
</script>
<style scoped>
.transparent {
  /*background-color: transparent;*/
}
h2 {
  color: var(--secondary);
  text-transform: capitalize;
}
h5,
h4 {
  /*font-size: 125%;*/
  font-weight: 400;
  color: var(--secondary);
  margin-bottom: 1rem;
}
.actions {
  /*position: absolute;*/
  bottom: 1rem;
}
.thumb {
  flex: 0 0 200px;
  max-height: 200px;
}
img {
  max-width: 100%;
}
.meta {
  padding: 0 1rem;
}
</style>
