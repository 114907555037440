<template>
  <div class="ProductView">
    <v-container class="d-flex justify-center mt-4 pa-4 rscope">
      <v-btn
        :disabled="isEditItem"
        @click="addNewItem()"
        x-large
        color="secondary"
        class="absRight"
      >
        {{ $t("store.newProduct") }}
      </v-btn>
    </v-container>
    <v-container v-if="!isNewItem && !isEditItem" class="content">
      <div class="tile-grid">
        <ProductTeaser
          v-for="item in data"
          :item="item"
          :key="item.id"
        ></ProductTeaser>
      </div>
    </v-container>
    <v-container v-if="isNewItem" content>
      <AddProductItem></AddProductItem>
    </v-container>
    <v-container v-if="isEditItem" content>
      <AddProductItem :item="activeItem"></AddProductItem>
    </v-container>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("app.deleteWarning") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn @click="finalize()" color="red lighten-1">
            {{ $t("app.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
import AddProductItem from "../components/AddProductItem.vue";
import ProductTeaser from "../components/ProductTeaser.vue";
export default {
  name: "Products",
  components: {
    AddProductItem,
    ProductTeaser,
  },
  data() {
    return {
      clients: [],
      data: [],
      dialog: false,
      activeItem: null,
      isNewItem: false,
      isEditItem: false,
    };
  },
  created() {
    this.getList();
    eventBus.$on("DELETE_ITEM", (item) => {
      this.deleteItem(item);
    });
    eventBus.$on("CANCEL_PRODUCT_ITEM", () => {
      this.isNewItem = false;
      this.isEditItem = false;
      this.activeItem = null;
      this.getList();
    });
    eventBus.$on("EDIT_ITEM", (item) => {
      this.editItem(item);
    });
    eventBus.$on("REFRESH", () => {
      this.getList();
    });
  },
  destroyed() {
    eventBus.$off("DELETE_ITEM");
    eventBus.$off("CANCEL_PRODUCT_ITEM");
    eventBus.$off("EDIT_ITEM");
    eventBus.$off("REFRESH");
  },
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `admin/products/filtered?keyword=`,
          payload: null,
        })
        .then((res) => {
          this.data = res;
        });
    },
    getMedia() {},
    deleteItem(item) {
      this.dialog = true;
      this.activeItem = item;
    },
    editItem(item) {
      this.isEditItem = false;
      this.activeItem = item;
      this.isEditItem = true;
    },
    finalize(item) {
      this.$store
        .dispatch("removeItem", { end: `admin/products/${this.activeItem.id}` })
        .then((res) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
          this.activeItem = null;
          this.dialog = false;
        });
    },
    addNewItem() {
      this.isNewItem = true;
    },
  },
};
</script>
<style scoped>
.tile-grid {
  display: flex;
  flex-wrap: wrap;
}
.rscope {
  position: relative;
}
.absRight {
  position: absolute;
  right: 0;
}
.tile-grid > * {
  flex: 1 1 40%;
  margin: 1rem 1rem 2rem;
}
</style>
