<template>
  <form class="comp-login">
    <v-card elevation="2" class="pa-4">
      <v-card-title v-if="!isEdit">{{ $t("store.newProduct") }}</v-card-title>
      <v-card-title v-if="isEdit">{{ $t("store.editItem") }}</v-card-title>
      <v-card-text>
        <v-form v-model="isValid">
          <v-row>
            <v-col>
              <v-subheader><b>Info</b></v-subheader>
              <v-text-field
                :label="$t('form.title')"
                outlined
                v-model="postData.name"
                required
                :rules="defaultRules"
              ></v-text-field>
              <v-textarea
                :label="$t('form.description')"
                outlined
                v-model="postData.description"
                required
                :rules="defaultRules"
              ></v-textarea>
              <v-text-field
                :label="$t('form.price')"
                outlined
                v-model="postData.price"
                type="number"
                required
                :rules="defaultRules"
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-btn
                    :disabled="!isValid"
                    v-if="isEdit"
                    x-large
                    color="secondary"
                    @click="put()"
                  >
                    {{ $t("form.send") }}
                  </v-btn>
                  <v-btn
                    :disabled="
                      !isValid ||
                      this.postData.images.length < 0 ||
                      this.postData.pdf === null
                    "
                    v-if="!isEdit"
                    x-large
                    color="secondary"
                    @click="submit()"
                  >
                    {{ $t("form.send") }}
                  </v-btn>
                  <v-btn @click="reset()" x-large>
                    {{ $t("form.cancel") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-subheader
                ><b>{{ $t("store.attachments") }}</b></v-subheader
              >
              <v-file-input
                prepend-icon="mdi-file"
                v-model="postData.pdf"
                :label="$t('store.productPdf')"
                accept="application/pdf"
              ></v-file-input>
              <v-file-input
                prepend-icon="mdi-camera"
                v-model="postData.images"
                :label="$t('form.cover')"
                multiple
                accept="image/png, image/jpeg, image/bmp"
              ></v-file-input>
              <v-subheader v-if="isEdit"><b>Csatolt Képek</b></v-subheader>
              <FilePreview :items="postData.images" type="files"></FilePreview>
              <div class="existing-images" v-if="isEdit">
                <FilePreview
                  :items="item.productImages"
                  type="array"
                ></FilePreview>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </form>
</template>
<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import { API_BASE } from "../util/constants.js";
import {
  getMultipartHeader,
  getLoggedInHeader,
  toQueryString,
} from "../util/functions.js";
import FilePreview from "./FilePreview.vue";
export default {
  name: "AddProductItem",
  components: { FilePreview },
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showDialog: true,
      isLocked: false,
      isValid: false,
      isEdit: false,
      postData: {
        name: "",
        description: "",
        price: 0,
        images: [],
        pdf: null,
      },
      defaultRules: [(v) => !!v || "Kötelező"],
      fileRules: [(v) => !!v || "Kötelező"],
    };
  },
  created() {
    eventBus.$on("REMOVE_PREVIEW_ITEM", (item) => {
      this.postData.images = this.postData.images.filter((image) => {
        return image.name !== item.name;
      });
    });
    eventBus.$on("DELETE_PREVIEW_ITEM", (item) => {
      this.deleteProductImage(item);
    });
  },
  destroyed() {
    console.log("destroyed");
    eventBus.$off("REMOVE_PREVIEW_ITEM");
    eventBus.$off("DELETE_PREVIEW_ITEM");
  },
  mounted() {
    // console.log(this.$jwt.decode());
    if (this.item) {
      this.isEdit = true;
      this.postData.name = this.item.name;
      this.postData.description = this.item.description;
      this.postData.price = this.item.price;
    }
  },
  computed: {
    files() {
      return this.postData.images;
    },
  },
  methods: {
    submit() {
      let formdata = new FormData();
      this.postData.images.forEach((item) => {
        formdata.append("images", item, item.name);
      });
      formdata.append("pdf", this.postData.pdf, this.postData.pdf.name);
      const requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };
      const query_string = toQueryString({
        description: this.postData.description,
        name: this.postData.name,
        price: this.postData.price,
      });
      fetch(`${API_BASE}admin/products?${query_string}`, requestOptions)
        .then((response) => {
          eventBus.$emit("CANCEL_PRODUCT_ITEM");
          eventBus.$emit("LOAD_OFF");
          eventBus.$emit("REFRESH");
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    uploadImages() {
      let formdata = new FormData();
      this.postData.images.forEach((item) => {
        formdata.append("images", item, item.name);
      });
      const requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };
      fetch(
        `${API_BASE}admin/products/${this.item.id}/product-images`,
        requestOptions
      )
        .then((response) => {
          eventBus.$emit("REFRESH");
          // eventBus.$emit("CANCEL_PRODUCT_ITEM");
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    uploadPdf() {
      let formdata = new FormData();

      formdata.append("pdf", this.postData.pdf, this.postData.pdf.name);

      const requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };
      fetch(`${API_BASE}admin/products/${this.item.id}/pdf`, requestOptions)
        .then((response) => {
          if (response.ok) {
            messageBus.$emit("onNewMessage", {
              type: "success",
              label: this.$t("message.success"),
            });
          }
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    put() {
      if (this.postData.images.length > 0) {
        this.uploadImages();
      }
      if (this.postData.pdf) {
        this.uploadPdf();
      }
      let data = {
        description: this.postData.description,
        name: this.postData.name,
        price: this.postData.price,
      };
      const requestOptions = {
        method: "PUT",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        body: JSON.stringify(data),
        redirect: "follow",
      };

      fetch(`${API_BASE}admin/products/${this.item.id}`, requestOptions)
        .then((response) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          console.log(response.text());
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    deleteProductImage(item) {
      const requestOptions = {
        method: "DELETE",
        headers: getLoggedInHeader(localStorage.getItem("token")),
      };
      fetch(`${API_BASE}admin/product-images/${item.id}`, requestOptions)
        .then((response) => {
          console.log("deleted");
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          // eventBus.$emit("REFRESH");
          // console.log(response.then((res) => res.text()));
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    reset() {
      eventBus.$emit("CANCEL_PRODUCT_ITEM");
    },
  },
};
</script>

<style scoped lang="scss"></style>
